<template>
  <div class="community" ref="container">
    <div class="top-image">
      <img :src="topImage" />
    </div>
    <div class="teacher" v-if="teacherList.length > 0">
      <ul>
        <li v-for="(item, index) in teacherList" :key="index">
          <router-link
            :to="{
              path: `/community/teacher/${item.uid}`,
              query: {name: item.nickname}
            }"
          >
            <img :src="item.avatar" :alt="item.nickname" />
            <p class="ell">{{ item.nickname }}</p>
          </router-link>
        </li>
        <li>
          <router-link :to="`/community/more`">
            <i class="jym-increase"></i>
            <p>关注更多</p>
          </router-link>
        </li>
      </ul>
    </div>

    <div v-if="communityLsit.length > 0">
      <div class="scroll-container">
        <ul class="lesson-list">
          <li class="container" v-for="(item, index) in communityLsit" :key="index">
            <div class="title">
              <router-link
                :to="{
                  path: `/community/teacher/${item.uid}`
                }"
              >
                <img :src="item.avatar" />
                <p>
                  <strong>{{ item.nickname }}</strong>
                  <span>{{ item.create_time }}</span>
                </p>
              </router-link>
              <div
                class="share"
                @click="share(item.is_care == 0 ? 1 : 0, item.uid)"
              >{{ item.is_care == 0 ? '＋ 关注': '取消关注' }}</div>
            </div>
            <div class="content">
              <router-link :to="`/community/detail/${item.id}`">
                <p
                  ref="textContent"
                  :style="{ 'max-height': item.isShow ? textHeight : '' }"
                  v-html="item.content"
                ></p>
              </router-link>
              <span v-if="item.isShow" @click="item.isShow = false">
                <em>...</em>
                <br />全文
                <i class="jym-icon-test58"></i>
              </span>
              <span v-if="item.isShow == false" @click="item.isShow = true">
                收起
                <i class="jym-icon-test56"></i>
              </span>

              <div v-if="item.type == 1 && item.media.length">
                <div v-if="item.media.length == 1" class="figure-list one">
                  <img
                    v-for="(src, i) in item.media"
                    :key="i"
                    @click="ImagePreview(item.media, i)"
                    v-lazy="src"
                  />
                </div>
                <div
                  v-else-if="item.media.length % 2 == 0 && item.media.length < 6"
                  class="figure-list two"
                >
                  <div class="img-container">
                    <img
                      v-for="(src, i) in item.media"
                      :key="i"
                      @click="ImagePreview(item.media, i)"
                      v-lazy="src"
                    />
                  </div>
                </div>
                <div v-else class="figure-list three">
                  <div class="img-container">
                    <img
                      v-for="(src, i) in item.media"
                      :key="i"
                      @click="ImagePreview(item.media, i)"
                      v-lazy="src"
                    />
                  </div>
                </div>
              </div>
              <div class="video" v-if="item.type == 2">
                <video :src="item.media" width="100%" height="100%" controls></video>
              </div>
            </div>

            <div class="bottom">
              <span @click="praise(item.is_praise == 0 ? 1 : 0, item.id, index)">
                <i :class="item.is_praise == 1 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"></i>
                {{ item.praise_num == 0 ? '点赞' : item.praise_num}}
              </span>
              <span>
                <router-link
                  :to="{
                  path: `/community/detail/${item.id}`,
                  query: {comment: 'comment'}
                }"
                >
                  <i class="jym-pinglun1"></i>
                  {{ item.comment_num > 0 ? item.comment_num : '评论' }}
                </router-link>
              </span>
              <span @click="collect(item.is_collect == 0 ? 1 : 0, item.id, index)">
                <i :class="item.is_collect == 1 ? 'jym-shoucang1 orange' : 'jym-shoucang'"></i>
                {{ item.collect_num == 0 ? '收藏' : item.collect_num }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>

    <van-image-preview
      v-if="imageShow"
      v-model="imageShow"
      :images="images"
      :showIndex="false"
      :showIndicators="true"
      :startPosition="startPosition"
      @close="vanClose"
    ></van-image-preview>

    <Loading :loaded="loadend" :loading="loading"></Loading>
  </div>
</template>

<script>
import Loading from "@components/Loading";
import {
  communityListImage,
  getCommunityList,
  getCommunityTeacher,
  getCommunityCare,
  communityPraise,
  communityCollect,
} from "@api/community";

export default {
  name: "community-list",
  components: {
    Loading,
  },
  data() {
    return {
      uid: "",
      topImage: "",
      teacherList: {},
      communityLsit: [],
      page: 1,
      limit: 10,
      textHeight: null,
      loadend: false,
      loading: false,
      imageShow: false,
      images: [],
      initLoading: false,
      startPosition: 0,
    };
  },
  created() {
    this.getListImage();
    this.getTeacherList();
  },
  mounted() {
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore();
    });
    window.onresize = () => {
      this.communityLsit.forEach((ele, index) => {
        this.$set(
          this.communityLsit,
          index,
          Object.assign({}, ele, { isShow: null })
        );
      });
      setTimeout(() => {
        this.calculateText();
      }, 0);
    };
  },
  methods: {
    // 关闭图片预览
    vanClose() {
      this.images = [];
      this.imageShow = false;
    },

    // 图片预览
    ImagePreview(images, index) {
      this.images = images;
      this.imageShow = true;
      this.startPosition = index;
    },

    // 获取顶部图片
    getListImage() {
      communityListImage().then((res) => {
        this.$set(this, "topImage", res.data.cover_img);
      });
    },

    // 获取老师列表
    getTeacherList() {
      getCommunityTeacher(1, 10).then((res) => {
        this.$set(this, "teacherList", res.data.slice(0, 4));
      });
    },

    // 计算文字显示展开收起
    calculateText() {
      let dom = this.$refs.textContent;
      let page = (this.page - 2) * this.limit;
      for (let i = page; i < dom.length; i++) {
        // 获取文本一行高度
        let lineHeight = (window.innerWidth / 7.5) * 0.48;
        // 获取元素高度
        let curHeight = dom[i].offsetHeight;
        this.textHeight = `${lineHeight * 5}px`;
        if (curHeight > lineHeight * 5) {
          this.$set(
            this.communityLsit,
            i,
            Object.assign({}, this.communityLsit[i], { isShow: true })
          );
        } else {
          this.$set(
            this.communityLsit,
            i,
            Object.assign({}, this.communityLsit[i], { isShow: null })
          );
        }
      }
      this.loading = false;
    },

    // 获取列表
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      const params = {
        uid: "",
        page: that.page,
        limit: that.limit,
      };
      getCommunityList(params).then((res) => {
        that.communityLsit.push.apply(that.communityLsit, res.data); //apply();js将一个数组插入另一个数组;
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
        if (res.data.length > 0) {
          that.$nextTick(() => {
            that.calculateText();
          });
        } else {
          that.loading = false;
        }
      });
    },

    // 关注
    share(status, id) {
      let that = this;

      getCommunityCare({
        uid: id,
        status: status,
      })
        .then(() => {
          that.communityLsit.forEach((item, i) => {
            if (item.uid == id) {
              that.$set(that.communityLsit[i], "is_care", status);
            }
          });
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },

    // 点赞
    praise(status, id, index) {
      communityPraise({
        status: status,
        community_id: id,
      })
        .then(() => {
          this.$set(this.communityLsit[index], "is_praise", status);
          this.$set(
            this.communityLsit[index],
            "praise_num",
            status == 0
              ? --this.communityLsit[index].praise_num
              : ++this.communityLsit[index].praise_num
          );
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 收藏
    collect(status, id, index) {
      communityCollect({
        status: status,
        community_id: id,
      })
        .then(() => {
          this.$set(this.communityLsit[index], "is_collect", status);
          this.$set(
            this.communityLsit[index],
            "collect_num",
            status == 0
              ? --this.communityLsit[index].collect_num
              : ++this.communityLsit[index].collect_num
          );
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.community {
  padding-bottom: 1rem;
  .top-image {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .teacher {
    width: 100%;
    padding: 0.3rem 0 0.25rem;
    ul {
      display: flex;
      flex-flow: row nowrap;
      li {
        width: 20%;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        img {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 100%;
          margin: 0 auto;
        }
        i {
          font-size: 0.6rem;
          height: 0.8rem;
          width: 0.8rem;
          line-height: 0.8rem;
          border: 0.02rem dashed black;
          border-radius: 100%;
          margin-bottom: 0.04rem;
          display: inline-block;
        }
        p {
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.24rem;
          padding: 0 0.1rem;
        }
      }
    }
  }

  .scroll-container {
    .container {
      padding: 0.4rem 0 0.8rem 1.2rem;
      background: white;
      .figure-list {
        margin: 0;
        padding: 0;
      }
      .one {
        img {
          width: 3.6rem;
          height: 3.6rem;
          object-fit: cover;
        }
      }
      .two {
        .img-container {
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          margin: 0 -0.05rem;
          img {
            width: 2.4rem;
            height: 2.4rem;
            margin: 0.05rem;
            object-fit: cover;
          }
        }
      }
      .three {
        .img-container {
          display: flex;
          flex-flow: row wrap;
          margin: 0 -0.05rem;
          img {
            width: 1.9rem;
            height: 1.9rem;
            margin: 0.05rem;
            object-fit: cover;
          }
        }
      }
      .video {
        position: relative;
        width: 4rem;
        height: 2.8rem;
        overflow: hidden;
        i {
          position: absolute;
          color: white;
          font-size: 0.6rem;
          z-index: 999;
          top: 1.7rem;
          left: 2.75rem;
        }
      }

      .title {
        height: 0.72rem;
        position: relative;
        a {
          width: 100%;
          position: relative;
          display: block;
          img {
            width: 0.72rem;
            height: 0.72rem;
            border-radius: 100%;
            position: absolute;
            left: -0.9rem;
          }
          p {
            display: flex;
            flex-flow: column nowrap;
            strong {
              font-size: 0.28rem;
            }
            span {
              margin-top: 0.04rem;
              font-size: 0.2rem;
              color: #999;
            }
          }
        }
        .share {
          position: absolute;
          top: 0.3rem;
          right: 0;
          font-size: 0.26rem;
          color: #aaa;
          border: 1px solid #f1f1f1;
          border-right: none;
          padding: 0.08rem 0.24rem;
          border-radius: 0.3rem 0 0 0.3rem;
          i {
            font-size: 0.2rem;
            margin-right: 0.1rem;
          }
        }
      }
      .content {
        font-size: 0.28rem;
        line-height: 0.48rem;
        margin: 0.2rem 0;
        padding: 0 0.4rem 0 0;
        p {
          overflow: hidden;
          margin-bottom: 0.1rem;
          white-space: pre-wrap;
        }
        span {
          color: #daa070;
          vertical-align: middle;
        }
      }
      .bottom {
        width: 100%;
        font-size: 0.24rem;
        color: #999;
        line-height: 0.52rem;
        float: right;
        padding-right: 0.4rem;
        display: flex;
        justify-content: space-between;
        a {
          color: #999;
        }
        i {
          margin-right: 0.06rem;
        }
      }
    }
  }
}
</style>

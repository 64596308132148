import request from "@utils/request";

/**
 * 获取列表
 */
export function getCommunityList(params) {
  return request.get("/community/index", params, { login: false });
}

/**
 * 获取详情
 */
export function getCommunityDetail(id) {
  return request.get("/community/detail/" + id, {}, { login: false });
}

/**
 * 关注
 */
export function getCommunityCare(data) {
  return request.post("/community/care", data, { login: false });
}

/**
 * 获取评论
 */
export function getCommunityComment(community_id, page, limit, pid) {
  return request.get(
    "/community/get_comment/" + community_id + "/" + page + "/" + limit + "/" + pid,
    {},
    { login: false }
  );
}

/**
 * 发现点赞
 */
export function communityPraise(data) {
  return request.post("/community/praise", data, { login: true });
}

/**
 * 评论点赞
 */
export function commentPraise(data) {
  return request.post("/community/comment_praise", data, { login: true });
}

/**
 * 老师列表
 */
export function getCommunityTeacher(page, limit) {
  return request.get(
    "/community/teacher/" + page + "/" + limit,
    {},
    { login: false }
  );
}

/**
 * 发现列表顶部封面图片
 */
export function communityListImage() {
  return request.get(
    "/community/top", {}, { login: false }
  );
}

/**
 * 发现主页
 */
export function communityPerson(uid) {
  return request.get(
    "/community/person/" + uid, {}, { login: true }
  );
}

/**
 * 提交评论
 */
export function communityComment(data) {
  return request.post("/community/comment", data, { login: true });
}

/**
 * 删除评论
 */
export function communityDelComment(id) {
  return request.get(
    "/community/del_comment/" + id, {}, { login: true }
  );
}

/**
 * 发现收藏
 */
export function communityCollect(data) {
  return request.post("/community/collect", data, { login: true });
}

/**
 * 发现删除
 */
export function communityDelete(uid) {
  return request.get("/community/delete/" + uid, {}, { login: true });
}